.sidebar-backdrop {
  background: #00000010;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}

.sidebar-backdrop.show {
  opacity: 1;
  pointer-events: all;
}

#sidebar form {
  top: 0;
  left: 0;
  height: calc(100% - 80px);
  position: absolute;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}

#sidebar form.show {
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
}

#sidebar form span {
  transform: scale(0.8);
  transition: all 0.3s ease;
}

#sidebar form.show span {
  transform: scale(1);
}

#sidebar form h2 {
  font-weight: 500;
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#sidebar form button {
  float: right;
  margin-right: 5px;
  margin-top: 5px;
  padding: 15px 0px;
  border-radius: 8px;
  font-size: 1.2em;
  background: transparent;
  color: #50B3FF;
  font-weight: 500;
}

#sidebar {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  overflow: auto;

  padding: 20px;

  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  box-shadow: 20px 0px 50px -20px #00000040;
  border-left: 1px solid #E1E1E1;

  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
  transition: all 0.3s ease;
}

#sidebar.show {
  opacity: 1;
  transform: none;
  pointer-events: all;
}

#sidebar header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E1E1E1;
  margin-left: -20px;
  padding: 0px 20px 20px 20px;
  width: calc(100% + 40px);
}

#sidebar header .close-arrow {
  float: left;
  width: 30px;
  height: 30px;
  padding: 7px;
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
}

#sidebar header .close-arrow:hover {
  background: #F1F1F1;
}

#sidebar header .close-arrow:active {
  background: #E1E1E1;
}


#sidebar header .avatar {
  float: right;
  width: 30px;
  height: 30px;
  background: #E1E1E1;
  border-radius: 50%;
}

#sidebar header h2 {
  font-size: 1.2em;
  display: inline-block;
  margin: 3px 0 0 0;
  font-weight: 500;
}

#sidebar .notes {
  transition: all 0.3s ease;
  transition-delay: 0.3s;
  transform: translateX(0px);
}

#sidebar .notes.hide {
  opacity: 0;
  transform: translateX(20px);
}

#sidebar .notes .note {
  line-height: 1.4;
  font-size: 1.2em;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  margin: 20px 0px;
}

#sidebar .note .note-comment {

}

#sidebar .notes section > :first-child {
  margin-top: 0px;
}

#sidebar .notes section > :last-child {
  margin-bottom: 0px;
}


#sidebar .note > :last-child {
  margin-bottom: 0px;
}

#sidebar .note section p {
  color: #00000040;
}

#sidebar .note section p span.range-selection {
  color: #000;
}

#sidebar .note-comment {
  font-size: 1rem;
  margin: 20px 0px;
}

#sidebar .note-comment > * {
  display: inline-block;
  vertical-align: top;
}

#sidebar .note-comment .avatar {
  width: 40px;
  height: 40px;
  background: #E1E1E1;
  border-radius: 50px;
}

#sidebar .note-comment-block {
  max-width: calc(100% - 40px);
  padding-left: 10px;
}

#sidebar .note-comment .author {
  font-weight: 600;
}
