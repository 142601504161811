button,
input,
select,
textarea {
  font-size: 1.2em;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  border: none;
  outline: none;
  background: transparent;
}

button {
  cursor: pointer;
}

form.form {
  position: relative;
  margin: -5px;
}

form.form .input-box {
  display: inline-block;
  position: relative;
  border-bottom: 1px solid var(--border-medium);
  margin: 5px;
  vertical-align: top;
  font-family: "Roboto Mono";
  font-size: 0.85em;
  /* this is because the height seems to go from 60px => 50.98px which causes
     a little pixel jump. */
  height: 60px;
  transition: all 0.3s ease;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: calc(100% - 10px);
}

form.form .input-box.input-box--div {
  height: auto;
}

form.form .input-box.input-box--textarea {
  border: 1px solid;
  height: auto;
  min-height: 150px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.form.form .input-box.input-box--textarea .max-length {
  position: absolute;
  bottom: 10px;
  left: 0px;
  font-size: 0.8em;
  opacity: 0.5;
  font-weight: 400;
}

.input-box input,
.input-box .input {
  background: transparent;
  padding: 22px 10px 15px 15px;
  transition: all 0.3s ease;
  font-weight: normal;
}

.input-box input {
  width: 100%;
}

.input:not(:empty)::before {
  content: attr(data-before);
}

.input-box span {
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 1.2em;
  font-weight: 400;
  transition: all 0.3s ease;
  opacity: 0.5;
}

.input-box input:not(:invalid) + span,
.input-box--textarea div:empty + span,
.input-box .input:not(:empty) + span,
.input-box.input-box--tag-field span {
  font-family: var(--sans-serif);
  font-size: 0.85em;
  font-weight: 600;
  top: 14px;
  left: 10px;
  opacity: 1;
}

.input-box.input-box--textarea div:empty + span,
.input-box.input-box--textarea div:not(:empty) + span,
.input-box.input-box--textarea div:empty,
.input-box.input-box--textarea div:not(:empty) {
  padding-left: 10px;
}

.input-box input:invalid + span::before {
  content: attr(data-placeholder);
}

.input-box input:invalid + span .label-content {
  display: none
};

.input-box input:not(:invalid) + span .label-content {
  display: block;
}

.input-box--textarea .input {
  padding: 29px 10px 11px 10px;
}

.input-box input:not(:invalid),
.input-box--textarea input,
.input-box .input:not(:empty) {
  padding: 29px 10px 11px 10px;
}

div[contenteditable="true"] {
  outline: none;
}

div[contenteditable="true"]:empty::before {
  content: attr(data-placeholder);
  opacity: 0.5;
}

.form .input-box.input-box--tag-field {
  height: auto;
}

.form .tags {
  margin-top: 30px;
}

.form .tags .input,
.form .tags .tag {
  display: inline-block;
  vertical-align: top;
  padding: 3px 5px 4px 5px;
  border: 1px solid var(--border-color);
  margin: 0px 3px 5px 3px;
  font-weight: 400;
}

.form .tags {
  margin-left: -3px;
}

.form .tags .input {
  min-width: 50px;
  border: 1px solid transparent;
}

.form .tags .tag:focus {
  border: 1px solid var(--border-medium);
  outline: none;
}

.form .part {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s ease;
}

.form .part.animate-up {
  transform: translateY(-100%);
  opacity: 0;
}

.form .part.animate-down {
  transform: translateY(100%);
  opacity: 0;
}

form.form .input-box.range  {
  position: relative;
  padding: 25px 20px;
  height: auto;
}

.range .range--track {
  position: relative;
  height: 1px;
  background: #000;
  border-radius: 4px;
}

.range .range--fill-track {
  position: absolute;
  top: 0;
  /*background: var(--gold);*/
  height: 1px;
  border-radius: 4px;
}

.range .range--thumb {
  position: absolute;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #000;
  /*box-shadow: 0px 2px 4px -1px var(--text);*/
  transform: translateY(-50%);
  color: #fff;
  text-align: center;
}

.range .range--thumb .range--value {
  position: absolute;
  font-family: var(--monospace);
  line-height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  letter-spacing: 1px;
}

.range .range-labels {
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.range .range-labels > div {
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
}

.range .range-labels .right-label {
  float: right;
}

.range .range--wrapper {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 80px);
}

form.form .input-box.range {
  border-bottom: none;
  margin: 0px;
}

.range .range--action {
  display: inline-block;
  vertical-align: top;
  width: 40px;
  font-size: 1.2em;
  margin-top: -11px;
  text-align: center;
  font-weight: 400;
  font-family: var(--sans-serif);
}

.range .action--left {
  padding-right: 10px;
}

.range .action--right {
  padding-left: 10px;
}

.range .label {
  display: block;
  position: static;
  text-align: center;
  margin-top: 15px;
  opacity: 1;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 1px;
}
