@import "./alert.css";
@import "./comment-box.css";
@import "./keyboard.css";
@import "./sidebar.css";
@import "./popover.css";
@import "./form.css";

* {
  -webkit-font-smoothing: antialiased;
  font-family: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0px;
}

#root {
  max-width: 1400px;
  margin: 0 auto;
}

.demo {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease;
}

.demo.hide {
  opacity: 0.3;
  transform: scale(0.9);
}

#finishedScreen,
#screenSizeTooSmall {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  transform: scale(0.9);
  opacity: 0;
  transition: all 0.3s ease;
  transition: 0.3s;
  pointer-events: none;
  z-index: 2;
}

#screenSizeTooSmall {
  background: #fff;
}

#finishedScreen.show {
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.demo-footer {
  width: 400px;
}

.demo-header h1,
.demo-footer h1 {
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;
}

.demo-header h2,
.demo-footer h2 {
  font-size: 1.1em;
  line-height: 1.4;
  font-weight: 400;
  width: 100%;
  text-align: center;
  opacity: 0.5;
}

.demo-footer h2 {
  position: relative;
  height: 100px;
}

.demo-footer h2 .step {
  position: absolute;
  transform: translate(0, 5px) scale(0.9);
  width: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
}

.demo-footer h2 .step.show {
  transform: translate(0, 0) scale(1);
  opacity: 1;
  pointer-events: all;
}

article {
  line-height: 1.4;
  font-size: 1.5em;
  letter-spacing: 0.5px;
}

a {
  color: #78beff;
}

#textFragment {
  display: inline-flex;
  vertical-align: top;
  width: calc(100% - 700px);
  height: 500px;
  padding: 50px;
  justify-content: center;
  align-items: center;
}

#textFragment p {
  transition: all 0.3s ease;
}

#textFragment p.hidden {
  opacity: 0;
  margin-bottom: -150px;
}

.demo-header,
.demo-footer {

}

.range-selection {
  background: #c4f4bd;
  cursor: pointer;
  transition: all 0.2s ease;
}

.selection--active,
.selection--active .range-selection {
  background: #8bec7e;
}

::selection {
  background: #50e03a40;
}

[contenteditable] {
  outline: none;
}

b {
  font-weight: 600;
}

button {
  background: #50B3FF;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  vertical-align: bottom;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
}

button:hover,
button:focus {
  background: #3b9eeb;
}

button.ghost {
  background: transparent;
  color: inherit;
}

@media (max-width: 1280px) {
  #textFragment {
    width: calc(100% - 600px);
  }

  #keyboardSVG {
    width: 500px;
  }
}

@media (max-width: 1024px) {
  #screenSizeTooSmall {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}

@media (max-height: 800px) {
  article {
    font-size: 1.2em;
  }

  #textFragment, .keyboard {
    height: 350px;
  }
}

@media (max-height: 650px) {
  #screenSizeTooSmall {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}
