.keyboard {
  display: inline-flex;
  height: 500px;
  justify-content: center;
  align-content: center;
  padding: 50px;
}

#keyboardSVG {
  display: inline-block;
  vertical-align: top;
  width: 600px;
}

#keyboardSVG rect,
#keyboardSVG path {
  transition: all 0.1s ease;
}

#keyboardSVG rect.active,
#keyboardSVG .active rect {
  stroke: #3d3d3d;
}

#keyboardSVG path.active,
#keyboardSVG .active path {
  fill: #3d3d3d;
}
