.comment-box-wrapper {
  position: absolute;
  perspective: 200px;
  transform: translate(-50%, -60px);
}

.comment-box {
  transform: rotate3d(1, 0, 0, 20deg) scale(0.9);
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #00000020;
  box-shadow: 0px 20px 30px -20px #00000080;
  opacity: 0;
  transition: all 0.3s ease;
}

.comment-box-wrapper.show .comment-box {
  opacity: 1;
  transform: rotate3d(1, 0, 0, 0deg) scale(1);
}

.comment-box > * {
  display: inline-block;
  vertical-align: top;
}

.comment-box .avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: blue;
}

.comment-box .comment {
  padding: 5px 10px;
  width: 300px;
  line-height: 1.4;
}

.comment-box [contenteditable]:empty::before {
  content: attr(data-placeholder);
  opacity: 0.5;
}
